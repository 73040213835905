import React, { useCallback, useContext, useEffect, useState } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import events from 'utilities/amplitude/events';
import {
  matchCorrectionsWithMeasurementUnits,
  MEASUREMENT_UNITS
} from 'helpers/unitsAbbreviation';
import useProductSearch from 'components/ProductSideBar/helpers/useProductSearch';
import useProduct from 'hooks/useProduct';

import { AgVendMatchContext } from '../context';
import { AgVendContext } from '../../context/Provider';
import { findProductByCwfId, findProductMatch, isValidUnit } from '../helpers';
import AgVendUnitList from '../components/UnitList';

const AgVendUnitsMatch = () => {
  const [productsUnitMatches, setProductsUnitMatches] = useState([]);
  const [unMatchedCount, setUnMatchedCount] = useState(0);
  const [cwfProducts, setCwfProducts] = useState([]);
  const { triggerAnalyticsEvent } = useSubmissionAnalytics();

  const { selectedInvoices } = useFormikHandler();
  const { getProduct, product: loadedProduct } = useProduct();
  const { searchProductById } = useProductSearch();

  const { releaseStepForward } = useContext(AgVendContext);
  const {
    isLoading,
    state: { unitsMatches, matches },
    updateUnitMatches,
    submitUnitsUpdates
  } = useContext(AgVendMatchContext);

  const onNextHandler = async () => {
    await submitUnitsUpdates();
    triggerAnalyticsEvent(
      events.epic.Integrations.IncomingInvoice.invoiceMatchUnits,
      {
        vendor: INTEGRATIONS.agVend
      }
    );
  };

  // This should bring the cwf products data from matches
  useEffect(() => {
    if (!matches?.length) {
      return;
    }

    async function fetchProducts(ids) {
      const products = await Promise.all(
        ids.map(async id => {
          const cwfProduct = await searchProductById(id);
          if (cwfProduct) {
            return cwfProduct;
          }
          return getProduct(id);
        })
      );
      setCwfProducts(old => [...old, ...products]);
    }

    const productsToFetch = [
      ...new Map(
        matches
          ?.filter(match => match.type === 'Product')
          ?.map(match => [match.cwfEntityId, match.cwfEntityId])
      ).values()
    ];

    if (productsToFetch?.length) {
      fetchProducts(productsToFetch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  useEffect(() => {
    if (!cwfProducts?.length) {
      return;
    }
    const unitMatches = selectedInvoices?.map(invoice => ({
      ...invoice,
      products: invoice.products?.map(product => {
        const cwfId = findProductMatch(matches, product)?.cwfEntityId;
        const cwfProduct = findProductByCwfId(cwfProducts, cwfId);
        return {
          ...product,
          cwfProduct,
          unitMatched: isValidUnit(product.totalQuantityUnit, MEASUREMENT_UNITS)
        };
      })
    }));
    setProductsUnitMatches(unitMatches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices, cwfProducts]);

  const onChangeUnitQuantity = useCallback(
    (invoiceId, productId, unit, quantity) => {
      if (!unit || !quantity) {
        return;
      }
      const measurementUnit = matchCorrectionsWithMeasurementUnits(unit);
      const updatedMatches = unitsMatches ? [...unitsMatches] : [];

      const invoiceIndex = updatedMatches.findIndex(
        invoice => invoice.id === invoiceId
      );

      if (invoiceIndex === -1) {
        // If the invoice does not exist, add it
        const newMatches = [
          ...updatedMatches,
          {
            id: invoiceId,
            productsUnits: [
              { id: productId, unit: measurementUnit, value: quantity }
            ]
          }
        ];
        updateUnitMatches(newMatches);
        return;
      }

      updatedMatches[invoiceIndex] = {
        ...updatedMatches[invoiceIndex],
        productsUnits: updatedMatches[invoiceIndex].productsUnits.some(
          p => p.id === productId
        )
          ? updatedMatches[invoiceIndex].productsUnits.map(p =>
              p.id === productId
                ? { ...p, unit: measurementUnit, value: quantity }
                : p
            )
          : [
              ...updatedMatches[invoiceIndex].productsUnits,
              { id: productId, unit: measurementUnit, value: quantity }
            ]
      };

      updateUnitMatches(updatedMatches);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unitsMatches]
  );

  useEffect(() => {
    if (!productsUnitMatches?.length) {
      return;
    }
    setUnMatchedCount(
      [...unitsMatches, ...productsUnitMatches]?.reduce((acc, inv) => {
        if (inv.productsUnits?.length) {
          return acc - inv.productsUnits.length;
        }

        return (
          acc +
          inv.products?.reduce((accInternal, product) => {
            return accInternal + (product.unitMatched ? 0 : 1);
          }, 0)
        );
      }, 0)
    );
  }, [productsUnitMatches, unitsMatches]);

  useEffect(() => {
    if (unMatchedCount === 0) {
      releaseStepForward();
    }
  }, [unMatchedCount, releaseStepForward]);

  const nextDisabled = loadedProduct || isLoading || unMatchedCount > 0;

  return (
    <>
      <AgVendUnitList
        invoices={productsUnitMatches}
        onChangeUnit={onChangeUnitQuantity}
        unMatchedCount={unMatchedCount}
      />
      <SubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Match Units of Measure"
        nextDisabled={nextDisabled}
        integrationType={INTEGRATIONS.agVend}
        isBottomFixed
        isLastStep={false}
        context={AgVendContext}
      />
    </>
  );
};

export default AgVendUnitsMatch;
