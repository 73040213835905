import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Context } from 'components/Store';
import catchCancel from 'helpers/catchCancel';
import { parseServerError } from 'helpers/errorHelpers';
import PAGINATION_SIZE, {
  GET_ALL_PRODUCTS_IN_ORG
} from 'helpers/globalConstants';
import { inventoryProduct as productApi } from 'utilities/api';
import axios from 'axios';
import { getAccessToken } from 'utilities/base-auth';
import { CROPWISE_PROXY_URL } from 'utilities/apiConstants';
import filteredProducts from 'screens/ProductList/helpers/filteredProducts';

const useProducts = defaultParams => {
  const [{ organization }, dispatch] = useContext(Context);
  const orgIdRef = useRef(organization.id);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoadingAllProducts, setIsLoadingAllProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productsCount, setProductsCount] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [request, setRequest] = useState({
    pageNo,
    size: PAGINATION_SIZE,
    ...defaultParams
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const emptySelectedProduct = [];

  useEffect(() => {
    orgIdRef.current = organization.id;
  }, [organization]);

  const getProducts = async params => {
    setIsLoading(true);
    const config = {
      ...request,
      ...params
    };

    const headers = {
      'Content-Type': 'application/json',
      'cwf-context': JSON.stringify({
        organization_id: orgIdRef.current
      }),
      Authorization: `Bearer ${getAccessToken()}`
    };

    const promise = axios.get(`${CROPWISE_PROXY_URL}/v1/inventory/products`, {
      headers,
      params: {
        ...config
      }
    });

    await promise
      .then(({ data }) => {
        if (config?.searchBy?.length) {
          setProducts(
            filteredProducts(
              data.results,
              config?.searchBy,
              emptySelectedProduct
            )
          );
          setProductsCount(data.totalElements);
          return;
        }
        setProducts(data.results);
        setProductsCount(data.totalElements);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllUseProducts = async () => {
    setIsLoadingAllProducts(true);
    const promise = axios.get(`${CROPWISE_PROXY_URL}/v1/inventory/products`, {
      headers: {
        'cwf-context': JSON.stringify({
          organization_id: organization.id
        }),
        Authorization: `Bearer ${getAccessToken()}`
      },
      params: {
        size: GET_ALL_PRODUCTS_IN_ORG
      }
    });

    await promise
      .then(({ data }) => {
        setAllProducts(data.results);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setIsLoadingAllProducts(false);
      });
  };

  const getProduct = async productId => {
    setIsLoading(true);
    const { promise } = await productApi.fetch(productId);

    await promise
      .then(({ data }) => {
        setSelectedProduct(data);
      })
      .catch(catchCancel)
      .catch(err => {
        parseServerError(dispatch)(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reload = useCallback(async () => {
    getProducts(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const reset = () => {
    setRequest({
      ...request,
      pageNo,
      totalPages: 0,
      size: PAGINATION_SIZE
    });
  };

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, request]);

  const filterByNameQuery = useCallback(
    newQuery => {
      setRequest(r => ({ ...r, pageNo: 0, searchBy: newQuery }));
    },
    [setRequest]
  );

  const filterByProductTypes = useCallback(
    newProductTypes => {
      setRequest(r => ({
        ...r,
        pageNo: 0,
        productTypes: Array.isArray(newProductTypes)
          ? newProductTypes.join(',')
          : newProductTypes
      }));
    },
    [setRequest]
  );

  const filterBySources = useCallback(
    newSources => {
      setRequest(r => ({
        ...r,
        pageNo: 0,
        sources: Array.isArray(newSources) ? newSources.join(',') : newSources
      }));
    },
    [setRequest]
  );

  const goToPage = useCallback(
    (newPage, params) => {
      setPageNo(newPage);
      setRequest(r => ({ ...r, ...params, pageNo: newPage }));
    },
    [setRequest]
  );

  const sort = useCallback(
    (field, dir) => {
      setRequest(r => ({ ...r, pageNo: 0, sortBy: field, sortDir: dir }));
    },
    [setRequest]
  );

  return {
    getProducts,
    products,
    productsCount,
    pageNo: request.pageNo,
    goToPage,
    isLoading,
    reset,
    reload,
    filterByNameQuery,
    filterByProductTypes,
    filterBySources,
    sort,
    sortBy: request.sortBy,
    sortDir: request.sortDir,
    getAllUseProducts,
    allProducts,
    isLoadingAllProducts,
    getProduct,
    selectedProduct
  };
};

export default useProducts;
