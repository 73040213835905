import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ProductListItem from './components/ProductListItem';
import { AgVendMatchContext } from '../../context';

const ProductList = ({ products, onSelectMatch }) => {
  const {
    state: { matches }
  } = useContext(AgVendMatchContext);

  return (
    <div className="grid w-full h-full mb-5">
      {products?.map(product => (
        <ProductListItem
          key={product.externalId}
          product={product}
          matchedProduct={
            matches.find(m => m.vendorEntityId === product.retailerId) ??
            matches.find(m => m.vendorEntityId === product.vendorId)
          }
          handleSelect={cwfSelectedProduct => {
            onSelectMatch(product, cwfSelectedProduct);
          }}
        />
      ))}
    </div>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      productName: PropTypes.string
    })
  ).isRequired,
  onSelectMatch: PropTypes.func.isRequired
};

export default ProductList;
