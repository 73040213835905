import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import FileLines from 'components/Icons/FileLines';

import StepHeader from 'screens/Integrations/components/StepHeader';

import { AgVendMatchContext } from '../../context';
import UnitListItem from './components/UnitListItem';
import { sortInvoices } from '../../helpers';

const AgVendUnitList = ({ invoices, unMatchedCount, onChangeUnit }) => {
  const { isLoading } = useContext(AgVendMatchContext);
  const sortedInvoices = useMemo(() => sortInvoices(invoices), [invoices]);
  return (
    <div
      className="w-84/100 bg-white flex flex-col px-6 py-4 rounded-xl font-body mb-5 shadow-md"
      data-testid="retailers-match-view"
    >
      <div
        className="flex flex-col w-full mb-5"
        id="retailers-match-top-header"
      >
        <StepHeader
          title="Match Units of Measure"
          description="Match units of measure from your AgVend invoices to available Cropwise Financials units."
          isMatchCountVisible
          isLoading={isLoading}
          unmatchCount={unMatchedCount}
        />
      </div>
      {sortedInvoices?.map(invoice => (
        <div className="mt-2" key={invoice.id}>
          <div className="flex items-center">
            <div className="flex flex-row items-center text-xl font-semibold text-gray-800">
              <FileLines color="#696F88" />
              <span className="ml-2 text-gray-800" data-testid="retailer-name">
                {invoice.invoiceNumber}
              </span>
            </div>
          </div>
          <div className="w-full mb-5 overflow-y-auto">
            {invoice.products?.map(product => (
              <UnitListItem
                key={product.retailerId || product.vendorId}
                invoice={invoice}
                product={product}
                cwfProduct={product.cwfProduct}
                matchedUnit={product.unitMatched}
                handleChange={onChangeUnit}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

AgVendUnitList.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      invoiceNumber: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          retailerId: PropTypes.string,
          vendorId: PropTypes.string,
          productName: PropTypes.string,
          unitMatched: PropTypes.string
        })
      )
    })
  ).isRequired,
  unMatchedCount: PropTypes.number.isRequired,
  onChangeUnit: PropTypes.func.isRequired
};

export default AgVendUnitList;
