/* eslint-disable import/prefer-default-export */

export const isNotMatched = (matches, entity) =>
  matches.findIndex(m => m.vendorEntityId === entity.externalId) === -1;

export const isProductNotMatched = (matches, entity) =>
  matches.findIndex(
    m =>
      m.vendorEntityId === entity.retailerId ||
      m.vendorEntityId === entity.vendorId
  ) === -1;

export const findProductMatch = (matches, entity) =>
  matches.find(
    m =>
      m.vendorEntityId === entity.retailerId ||
      m.vendorEntityId === entity.vendorId
  );

export const findProductByCwfId = (cwfProducts, cwfId) =>
  cwfProducts?.find(
    cwfProduct =>
      cwfProduct.id === cwfId || cwfProduct.id === cwfId?.replace(/-/g, '')
  );

export const sortInvoices = invoices => {
  if (!invoices?.length) {
    return invoices;
  }
  const sortedByNumber = invoices.sort((a, b) => {
    const comparison = a?.invoiceNumber?.localeCompare(
      b?.invoiceNumber,
      undefined,
      {
        numeric: true,
        sensitivity: 'base'
      }
    );
    if (comparison !== 0) {
      return comparison;
    }
    return 0;
  });

  const sortedByUnMatched = sortedByNumber.sort((a, b) => {
    const aHaveUnMatched = a.products?.some(product => !product.unitMatched);
    const bHaveUnMatched = b.products?.some(product => !product.unitMatched);

    if (aHaveUnMatched && !bHaveUnMatched) return -1;
    if (!aHaveUnMatched && bHaveUnMatched) return 1;
    return 0;
  });

  return sortedByUnMatched;
};

export const isValidUnit = (unit, validUnits) =>
  !!Object.values(validUnits).find(validUnit => unit === validUnit);

export const checkMatchesDuplicates = (matches, entitiesToMatch) => {
  let canSubmit = true;

  const cwfIds = matches
    .filter(
      m =>
        entitiesToMatch.findIndex(
          entity => entity.externalId === m.vendorEntityId
        ) >= 0
    )
    .map(match => match.cwfEntityId);
  const uniqueCwfIds = [...new Set(cwfIds)];
  const checkDuplicates = uniqueCwfIds.map(value => [
    value,
    cwfIds.filter(id => id === value).length
  ]);

  checkDuplicates.forEach(check => {
    if (check[1] > 1) {
      canSubmit = false;
    }
  });

  return canSubmit;
};
