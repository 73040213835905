import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import StepHeader from 'screens/Integrations/components/StepHeader';

import { AgVendMatchContext } from '../../context';
import ProductList from '../ProductList';
import RetailerList from '../RetailerList';

const AgVendMatchBoard = ({
  unMatchedEntities,
  entitiesToMatch,
  matchType,
  title,
  description
}) => {
  const {
    state: { matches },
    isLoading,
    updateMatches
  } = useContext(AgVendMatchContext);

  const handleSelectMatch = (agVendEntity, selectedEntity) => {
    const matchIdKey =
      matchType === 'Product'
        ? agVendEntity.retailerId
        : agVendEntity.externalId;

    if (!selectedEntity) {
      return updateMatches([
        ...matches.filter(
          m =>
            m.vendorEntityId !== matchIdKey &&
            m.vendorEntityId !== agVendEntity.vendorId
        )
      ]);
    }

    const matchs = [
      {
        vendorEntityDescription: agVendEntity.name,
        vendorEntityId: matchIdKey,
        cwfEntityId: selectedEntity.id,
        type: matchType
      }
    ];

    if (matchType === 'Product' && agVendEntity.vendorId) {
      matchs.push({
        vendorEntityDescription: agVendEntity.name,
        vendorEntityId: agVendEntity.vendorId,
        cwfEntityId: selectedEntity.id,
        type: matchType
      });
    }

    const nonAffected = matches.filter(
      m =>
        m.vendorEntityId !== matchIdKey &&
        m.vendorEntityId !== agVendEntity.vendorId
    );

    if (nonAffected.length !== matches.length) {
      return updateMatches([...nonAffected, ...matchs]);
    }
    return updateMatches([...matches, ...matchs]);
  };

  return (
    <div
      className="w-84/100 bg-white flex flex-col px-6 py-4 rounded-xl font-body mb-5 shadow-md"
      data-testid="retailers-match-view"
    >
      <div
        className="flex flex-col w-full mb-3"
        id="retailers-match-top-header"
      >
        <StepHeader
          title={title}
          description={description}
          isLoading={isLoading}
          isMatchCountVisible
          unmatchCount={unMatchedEntities.length}
        />
      </div>
      <div className="w-full mb-5 overflow-y-auto">
        {matchType === 'Company' && (
          <RetailerList
            retailers={entitiesToMatch}
            onSelectMatch={handleSelectMatch}
          />
        )}
        {matchType === 'Product' && (
          <ProductList
            products={entitiesToMatch}
            onSelectMatch={handleSelectMatch}
          />
        )}
      </div>
    </div>
  );
};

AgVendMatchBoard.propTypes = {
  unMatchedEntities: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  entitiesToMatch: PropTypes.arrayOf(
    PropTypes.shape({
      externalId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  matchType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default AgVendMatchBoard;
