import React, { memo } from 'react';
import PropTypes from 'prop-types';

const FileLines = ({ size, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="file-lines"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM12 10H4V12H12V10ZM12 14H4V16H12V14ZM2 18H14V7H9V2H2V18Z"
        fill={color}
      />
    </svg>
  );
};

FileLines.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

FileLines.defaultProps = {
  color: '#696F88',
  size: 20
};

export default memo(FileLines);
