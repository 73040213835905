import React, { useContext, useEffect, useState } from 'react';
import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useFormikHandler from 'screens/Integrations/hooks/useFormikHandler';
import SubmissionFooter from 'screens/Integrations/components/SubmissionFooter';
import useSubmissionAnalytics from 'screens/Integrations/hooks/useSubmissionAnalytics';
import events from 'utilities/amplitude/events';

import { AgVendMatchContext } from '../context';
import { AgVendContext } from '../../context/Provider';
import { isProductNotMatched } from '../helpers';
import AgVendMatchBoard from '../components/MatchBoard';

const AgVendProductsMatch = () => {
  const [unmatchedProducts, setUnmatchedProducts] = useState([]);
  const [productsToMatch, setProductsToMatch] = useState([]);
  const { triggerAnalyticsEvent } = useSubmissionAnalytics();

  const { selectedInvoices } = useFormikHandler();

  const { releaseStepForward } = useContext(AgVendContext);
  const {
    isLoading,
    state: { matches },
    fetchMatches,
    submitMatches
  } = useContext(AgVendMatchContext);

  useEffect(() => {
    if (!isLoading) {
      fetchMatches();
    }
    setProductsToMatch(
      selectedInvoices
        ?.flatMap(r => r.products)
        .reduce((acc, cur) => {
          if (
            acc.findIndex(p => p.retailerId === cur.retailerId) === -1 ||
            acc.findIndex(p => p.vendorId === cur.vendorId) === -1
          ) {
            acc.push({ ...cur, name: cur.productName });
          }
          return acc;
        }, [])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUnmatchedProducts(
      productsToMatch?.filter(product => isProductNotMatched(matches, product))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches, productsToMatch]);

  const onNextHandler = async () => {
    await submitMatches();

    triggerAnalyticsEvent(
      events.epic.Integrations.IncomingInvoice.invoiceMatchProducts,
      {
        vendor: INTEGRATIONS.agVend
      }
    );
  };

  useEffect(() => {
    if (!unmatchedProducts?.length) {
      releaseStepForward();
    }
  }, [unmatchedProducts, releaseStepForward]);

  const nextDisabled = isLoading || unmatchedProducts?.length;

  return (
    <>
      <AgVendMatchBoard
        unMatchedEntities={unmatchedProducts}
        entitiesToMatch={productsToMatch}
        matchType="Product"
        title="Match Products"
        description="Match products from your AgVend invoices to products on the Cropwise Financials product list."
      />
      <SubmissionFooter
        onNext={onNextHandler}
        loading={isLoading}
        nextLabel="Next: Match Units of Measure"
        nextDisabled={nextDisabled}
        integrationType={INTEGRATIONS.agVend}
        isBottomFixed
        isLastStep={false}
        context={AgVendContext}
      />
    </>
  );
};

export default AgVendProductsMatch;
