import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { INTEGRATIONS } from 'screens/Integrations/helpers/constants';
import useIncomingInvoiceApi from 'screens/Integrations/hooks/useIncomingInvoiceApi';

import reducer, {
  SET_MATCHES,
  SET_FETCHED_MATCHES,
  SET_UNIT_MATCHES
} from './reducer';

export const initialState = {
  matches: [],
  unitsMatches: [],
  fetchedMatches: [],
  errorMessage: '',
  matchEntityType: ''
};

export const AgVendMatchContext = createContext(initialState);

const AgVendMatchProvider = ({ children, onCompleteMatch }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    children,
    matchEntityType: ['Product', 'Company']
  });

  const {
    getMatches,
    createMatches,
    updatesUnitsUOM,
    loading: isLoading
  } = useIncomingInvoiceApi();

  const updateMatches = (payload, isFetched = false) => {
    dispatch({
      type: !isFetched ? SET_MATCHES : SET_FETCHED_MATCHES,
      payload
    });
  };

  const updateUnitMatches = payload => {
    dispatch({
      type: SET_UNIT_MATCHES,
      payload
    });
  };

  // eslint-disable-next-line consistent-return
  const submitMatches = async () => {
    const newOrUpdatedMatches = state.matches.filter(
      m =>
        state.fetchedMatches.findIndex(
          fetchedMatch =>
            m.cwfEntityId === fetchedMatch.cwfEntityId &&
            m.vendorEntityId === fetchedMatch.vendorEntityId
        ) === -1
    );
    if (newOrUpdatedMatches.length === 0) {
      return onCompleteMatch();
    }
    const response = await createMatches({
      vendor: INTEGRATIONS.agVend.toLowerCase(),
      matches: newOrUpdatedMatches
    });
    if (response?.status === 201) {
      onCompleteMatch();
    }
  };

  const submitUnitsUpdates = async () => {
    if (!state.unitsMatches.length) {
      onCompleteMatch();
      return;
    }

    const response = await updatesUnitsUOM({
      unitsUpdates: state.unitsMatches
    });
    if (response?.status === 201 || response?.status === 200) {
      onCompleteMatch();
    }
  };

  const fetchMatches = async () => {
    const response = await getMatches({
      vendor: INTEGRATIONS.agVend.toLocaleLowerCase(),
      matchTypes: state.matchEntityType
    });

    if (response?.status === 200) {
      updateMatches(response.data, true);
    }
  };

  const findProductInUnitMatches = (invoiceId, productId) => {
    const invoice = state.unitsMatches?.find(match => match.id === invoiceId);
    return invoice?.productsUnits?.find(product => product.id === productId);
  };

  const memoized = useMemo(
    () => ({
      state,
      integrationContext: INTEGRATIONS.agVend,
      dispatch,
      updateMatches,
      submitMatches,
      fetchMatches,
      updateUnitMatches,
      submitUnitsUpdates,
      findProductInUnitMatches,
      isLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, isLoading]
  );

  return (
    <AgVendMatchContext.Provider value={memoized}>
      {children}
    </AgVendMatchContext.Provider>
  );
};

AgVendMatchProvider.defaultProps = {
  children: null,
  onCompleteMatch: null
};

AgVendMatchProvider.propTypes = {
  children: PropTypes.node,
  onCompleteMatch: PropTypes.func
};

export default AgVendMatchProvider;
