import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Row, Col, Alert } from 'syngenta-digital-cropwise-react-ui-kit';
import {
  MEASUREMENT_UNITS,
  getAbbreviatedUnit
} from 'helpers/unitsAbbreviation';
import { getBushelWeight } from 'helpers/getBushelWeight';
import YieldQuantityField from '../YieldSectionContainer/components/YieldQuantityField';
import calculateAverageQuantity from '../../helpers/calculateAverageQuantity';
import calculateFinalQuantity from '../../helpers/calculateFinalQuantity';
import YieldQuantityDisplay from '../YieldSectionContainer/components/YieldQuantityDisplay';
import ShrinkField from './ShrinkField';

const YieldShrinkSection = ({ selectedCropName }) => {
  const {
    setFieldValue,
    values,
    validateForm,
    errors,
    setFieldTouched
  } = useFormikContext();
  const [finalYield, setFinalYield] = useState();
  const [averageFinalQuantity, setAverageFinalQuantity] = useState();

  const bushelWeight = useMemo(() => getBushelWeight(selectedCropName), [
    selectedCropName
  ]);

  const isPropertySelected = useMemo(() => {
    const isSourceSelected = values.sources !== undefined;
    return (
      isSourceSelected &&
      values.sourceType === 'CROP_ZONE' &&
      values.sources?.length > 0
    );
  }, [values.sources, values.sourceType]);

  const averageQuantity = useMemo(() => {
    return calculateAverageQuantity({
      quantity: values.harvestedQuantity,
      area: values.totalArea
    });
  }, [values.harvestedQuantity, values.totalArea]);

  const shrinkCalculations = async () => {
    /**
     * In case of error when shrink is larger than harvested quantity
     * Final Yield Qty and Avg. Final Yield Qty should not update with new value -
     * should remain as they were until error is corrected
     */
    const formErrors = await validateForm();
    if (values.shrink && formErrors.shrink) return;

    const recalculatedFinalYield = calculateFinalQuantity({
      harvestedQuantity: values.harvestedQuantity,
      shrink: values.shrink,
      shrinkType: values.shrinkType
    });

    setFinalYield(recalculatedFinalYield);
    setFieldValue('load', recalculatedFinalYield);
    setFieldTouched('load', true);
    setAverageFinalQuantity(
      calculateAverageQuantity({
        quantity: recalculatedFinalYield,
        area: values.totalArea
      })
    );
  };

  useEffect(() => {
    if (!selectedCropName || values.loadUnit) return;
    setFieldValue(
      'loadUnit',
      bushelWeight ? MEASUREMENT_UNITS.BUSHEL : MEASUREMENT_UNITS.POUND
    );
  }, [selectedCropName, setFieldValue, bushelWeight, values.loadUnit]);

  useEffect(() => {
    shrinkCalculations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.harvestedQuantity,
    values.shrink,
    values.shrinkType,
    values.totalArea
  ]);

  const loadAbbreviatedUnit = getAbbreviatedUnit(values.loadUnit);
  const displayShrinkAlert = !!errors?.shrink?.includes('less than');

  return (
    <Row gutter={[24, 24]} prefixCls="syt-antd-row">
      <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
        <YieldQuantityField
          fieldName="harvestedQuantity"
          selectedCropName={selectedCropName}
          label="Harvested Quantity"
        />
      </Col>
      {isPropertySelected && (
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <YieldQuantityDisplay
            label="Avg. Harvested Quantity"
            quantity={averageQuantity}
            unit={`${loadAbbreviatedUnit} / ac`}
          />
        </Col>
      )}
      <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
        <ShrinkField />
      </Col>

      {displayShrinkAlert && (
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={24}
          prefixCls="syt-antd-col"
          style={errors?.load ? {} : { marginTop: '-20px' }}
        >
          <Alert
            data-testid="error-banner"
            className="w-full px-8"
            message={
              <p className="font-normal text-neutral-80">{errors.shrink}</p>
            }
            showIcon
            type="error"
          />
        </Col>
      )}

      {!isPropertySelected && !displayShrinkAlert && (
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col" />
      )}
      <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
        <YieldQuantityDisplay
          label="Final Yield Quantity"
          quantity={finalYield}
          unit={loadAbbreviatedUnit}
        />
      </Col>
      {isPropertySelected && (
        <Col xs={24} sm={24} md={12} lg={12} xl={8} prefixCls="syt-antd-col">
          <YieldQuantityDisplay
            label="Avg. Final Yield Quantity"
            quantity={averageFinalQuantity}
            unit={`${loadAbbreviatedUnit} / ac`}
          />
        </Col>
      )}
    </Row>
  );
};

YieldShrinkSection.propTypes = {
  selectedCropName: PropTypes.string.isRequired
};

export default YieldShrinkSection;
