import React, { useContext } from 'react';

import AgVendInvoiceList from '../AgVendInvoiceList';
import { AgVendContext } from '../context/Provider';
import AgVendReviewAndImport from '../AgVendReviewAndImport';
import AgVendRetailersMatch from '../AgVendMatches/RetaleirsMatch';
import AgVendProductsMatch from '../AgVendMatches/ProductsMatch';
import AgVendUnitsMatch from '../AgVendMatches/UnitsMatch';

const STEPS = {
  1: <AgVendInvoiceList />,
  2: <AgVendRetailersMatch />,
  3: <AgVendProductsMatch />,
  4: <AgVendUnitsMatch />,
  5: <AgVendReviewAndImport />
};

const StepSelector = () => {
  const {
    state: { step }
  } = useContext(AgVendContext);

  return STEPS[step];
};

export default StepSelector;
