import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MatchArrowIcon from 'screens/Integrations/PropertiesMatch/components/FarmList/components/icons/MatchArrowIcon';
import {
  ChevronDown,
  Select,
  Tag
} from 'syngenta-digital-cropwise-react-ui-kit';
import useProduct from 'hooks/useProduct';
import { getCorrectUnitName, getUnitLabel } from 'helpers/unitsAbbreviation';
import GrowUIFormField from 'components/Forms/GrowUIFormField';
import ClickableDiv from 'components/ClickableDiv';
import { Input } from '@agconnections/grow-ui';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import './antd-override.css';
import { AgVendMatchContext } from 'screens/Integrations/AgVend/AgVendMatches/context';

const UnitListItem = ({
  invoice,
  product,
  cwfProduct,
  matchedUnit,
  handleChange
}) => {
  const {
    state: { unitsMatches },
    findProductInUnitMatches
  } = useContext(AgVendMatchContext);
  const [matched, setMatched] = useState(matchedUnit);
  const [openProduct, setOpenProduct] = useState(!matchedUnit);
  const { getMasterListProductUnits } = useProduct();
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState(
    getCorrectUnitName(
      !matchedUnit
        ? findProductInUnitMatches(invoice.id, product.id)?.unit
        : getCorrectUnitName(product.totalQuantityUnit)
    )
  );

  const [quantity, setQuantity] = useState(
    !matchedUnit
      ? findProductInUnitMatches(invoice.id, product.id)?.value
      : product.totalQuantityValue
  );

  useEffect(() => {
    if (cwfProduct?.id || cwfProduct?.productId) {
      getMasterListProductUnits(cwfProduct.id || cwfProduct.productId).then(
        masterListUnits => {
          setUnits(masterListUnits);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cwfProduct]);

  useEffect(() => {
    if (matchedUnit) {
      return;
    }

    const isMatched = findProductInUnitMatches(invoice.id, product.id);
    setMatched(!!isMatched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitsMatches, matchedUnit, invoice.id, product.id]);

  const onChangeQuantity = newQuantity => {
    setQuantity(newQuantity);
    handleChange(invoice.id, product.id, unit, newQuantity);
  };
  const onChangeUnit = newUnit => {
    setUnit(newUnit);
    handleChange(invoice.id, product.id, newUnit, quantity);
  };

  return (
    <div
      data-testid="product-collapse"
      className="border border-solid rounded-lg py-1 px-4 my-2 "
    >
      <ClickableDiv onClick={() => setOpenProduct(!openProduct)}>
        <div className="w-full">
          <div className="flex items-center">
            <div className="flex items-center w-full flex-row-reverse justify-end">
              <Tag
                key="product-status"
                className="p-10"
                color={matched ? 'green' : 'yellow'}
                data-testid="status-tag"
              >
                {matched ? 'MATCHED' : 'UNMATCHED'}
              </Tag>
              <span className="text-l text-gray-700 font-semibold">
                {cwfProduct.name}
              </span>
              <span>
                <MatchArrowIcon width={25} height={40} />
              </span>
              <span className="text-l text-gray-700 font-semibold">
                {product.productName}
              </span>
            </div>

            <div className="flex justify-end ">
              {openProduct ? (
                <ArrowDropUp fontSize="small" className="text-neutral-600" />
              ) : (
                <ArrowDropDown fontSize="small" className="text-neutral-600" />
              )}
            </div>
          </div>
        </div>
      </ClickableDiv>

      {openProduct && (
        <div className="flex items-center justify-start w-full mb-2  mt-2">
          <div className="flex flex-col flex-1">
            <span className="text-gray-600">Invoice Quantity</span>
            <div className="mt-1 px-2 h-8 rounded bg-neutral-10 border-neutral-30 border-1 text-gray-600 items-center flex flex-row justify-between">
              <span>{product.totalQuantityValue}</span>
              <span>{product.totalQuantityUnit}</span>
            </div>
          </div>
          <div className="pt-5">
            <MatchArrowIcon width={70} height={20} />
          </div>
          <div
            className="flex flex-row items-center pt-6 w-full"
            style={{ flex: '2', minWidth: '200px' }}
          >
            <div className="units-quantity">
              <GrowUIFormField
                control={Input}
                name={`quantity-${product.id}`}
                id={`quantity-${product.id}`}
                label="Cropwise Financials Quantity"
                disabled={matchedUnit}
                value={quantity}
                onChange={e => {
                  onChangeQuantity(e.target.value);
                }}
                suffixIcon={<ChevronDown />}
                imask={{
                  mask: 'num',
                  blocks: {
                    num: {
                      mask: Number,
                      scale: 4
                    }
                  }
                }}
              />
            </div>
            <div
              className="units-dropdown relative flex flex-col"
              style={{ minWidth: '12rem' }}
            >
              <Select
                data-testid="unit-select"
                size="lg"
                placeholder="Select unit"
                suffixIcon={<ChevronDown />}
                onChange={onChangeUnit}
                defaultValue={unit}
                value={unit}
                disabled={matchedUnit}
                dropdownMatchSelectWidth={false}
                optionLabelProp="label"
                optionFilterProp="label"
                showSearch
                required={!matchedUnit}
              >
                {units.map(_unit => (
                  <Select.Option
                    key={_unit.key}
                    value={_unit.key}
                    label={getUnitLabel(_unit.key)}
                    data-testid={`unit-option-${_unit.key}`}
                  >
                    {getUnitLabel(_unit.key)}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UnitListItem.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    retailerId: PropTypes.string,
    vendorId: PropTypes.string,
    productName: PropTypes.string,
    unitMatched: PropTypes.string,
    totalQuantityUnit: PropTypes.string,
    totalQuantityValue: PropTypes.number
  }).isRequired,
  cwfProduct: PropTypes.shape({
    id: PropTypes.string,
    productId: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  matchedUnit: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default UnitListItem;
